import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from '@akelius/angular-ui-kit';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../core/services/auth.service';
import { TranslationService } from '../../core/services/translation.service';
import { LightboxService } from '../../shared/core/services/lightbox.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NodeService } from '../../core/services/node.service';
import { ReleaseService } from '../../core/services/release.service';
import { NotificationService } from '../../core/services/notification.service';
import { filter, map, startWith } from 'rxjs/operators';
import { version } from '../../../version/version';
import { ContextFacadeService } from '../../core/facades/context-facade.service';
import { NodeData } from '../../core';
import { RouteData } from '../../app-routing.module';
import { ROLES, User } from '../../core/models/auth.interfaces';
import { TenantOption, TenantService } from '../../core/services/tenant.service';
import { WINDOW_OBJECT } from '../../core/core.module';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild('userMenuButton', { read: ElementRef, static: true }) userMenuButton: ElementRef;
  userProfile$: Observable<User>;
  isAuthenticated$: Observable<boolean>;
  showDebuggerMenu$: Observable<boolean>;
  showDeskBooking$: Observable<boolean>;
  fullWidth = false;
  headless = false;
  breadcrumbless = false;

  menuItems$: Observable<MenuItem[]>;

  translations$ = this.translationService.translationList$;

  userAllowedTenants$: Observable<TenantOption[] | null>;

  fixedHeight$: Observable<boolean>;

  headline: string;

  @ViewChild(MatSidenav, { static: true }) private drawer: MatSidenav;

  constructor(
    public authService: AuthService,
    public tenantService: TenantService,
    private translationService: TranslationService,
    public lightBoxService: LightboxService,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nodeService: NodeService,
    private releaseService: ReleaseService,
    public notificationService: NotificationService,
    private contextFacadeService: ContextFacadeService,
    @Inject(WINDOW_OBJECT) private _window: Window,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.checkBrowser();
    this.lightBoxService.setRootViewContainerRef(this.viewContainerRef);
  }

  ngOnInit() {
    this.checkHeadlessMode();

    this.headline = this.appConfig.headline;

    this.showDebuggerMenu$ = this.authService.hasRole(ROLES.debugger);

    this.userAllowedTenants$ = this.tenantService.userAllowedTenants$;

    this.showDeskBooking$ = this.authService.hasCountry(['Germany', 'United Kingdom', 'Canada', 'Sweden', 'Cyprus']);

    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.userProfile$ = this.authService.userProfile$ as Observable<User>;

    this.releaseService.initialize();

    this.fixedHeight$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRouteHasFixedHeight(this.activatedRoute)),
      startWith(this.activatedRouteHasFixedHeight(this.activatedRoute))
    );

    this.menuItems$ = this.nodeService.getNodesData('/').pipe(
      map((node: NodeData) => {
        const children =
          node?.children?.filter((filterNode) => !['context', 'disabled', 'hidden'].includes(filterNode.status)) || [];

        return children.map((childNode) => {
          const menuItem: MenuItem = {
            path: '',
            label: this.translationService.getTranslationById(childNode.translationKey),
            children: [],
          };
          if (childNode.template?.name === 'linkTo' && childNode.template.option) {
            menuItem.path = childNode.template.option;
            menuItem.openInNewTab = true;
          } else {
            menuItem.path = `/${childNode.name}`;
          }
          return menuItem;
        });
      })
    );
  }

  checkHeadlessMode(): void {
    const params = new URLSearchParams(this._window?.location?.search);
    this.headless = params.get('headless') === 'true' || params.get('headless') === '1';
    this.breadcrumbless = params.get('breadcrumbless') === 'true' || params.get('breadcrumbless') === '1';
  }

  checkBrowser() {
    const browsers = ['Chrome', 'Safari'];
    const isSupported = browsers.some((browser) => navigator.userAgent.indexOf(browser) > -1);

    if (!isSupported) {
      this.notificationService.showInformationDialog('core.unsupportedBrowser');
    }
  }

  showAppInfo(): void {
    this.authService.getTokenSilently$().subscribe({ next: (data) => console.info('access token', data) });
    this.authService.getIdToken$().subscribe({ next: (data) => console.info('id token', data) });

    const { headline, envName, backend } = this.appConfig;

    const appInfo = {
      title: headline,
      environment: envName,
      backend,
      buildTime: version.build.time,
      gitBranch: version.git.branchName,
      gitCommit: version.git.commitHash,
    };

    console.info('app info', appInfo);
  }

  onLogout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {}

  goHome() {
    this.router.navigateByUrl('/');
  }

  goToServiceDesk() {
    this._window.open('https://akelius.atlassian.net/servicedesk/customer/portals', '_blank');
  }

  goToRemoteDesktop() {
    this._window.open('https://client.wvd.microsoft.com/arm/webclient/index.html', '_blank');
  }

  toggleFullscreen() {
    this.fullWidth = !this.fullWidth;
  }

  openSearch() {
    this.contextFacadeService.resetStore();
    this.router.navigateByUrl('/search');
  }

  debugTranslationToggle() {
    this.translationService.toggleMarkTranslations();
  }

  selectLanguage() {
    this.router.navigateByUrl('/language');
  }

  showUserDetails(userData: User) {
    this.router
      .navigate(['/staffnode/catalog/employees/', userData.id, userData.given_name + ' ' + userData.family_name])
      .then();
  }

  openURL(url: string) {
    this._window.open(url, '_blank');
  }

  goToMyProfile() {
    this.openURL('https://myaccount.microsoft.com/');
  }

  goToContacts() {
    this.openURL('https://outlook.office.com/people');
  }

  goToCalendar() {
    this.openURL('https://outlook.office.com/calendar/view/month');
  }

  goToDeskBooking() {
    this.openURL('https://hybrid.officernd.com/community/akelius');
  }

  showSystemInfos() {
    this.router.navigateByUrl('/system-info');
  }

  private activatedRouteHasFixedHeight(activatedRoute: ActivatedRoute): boolean {
    const data = this.getActivatedRouteData(activatedRoute);
    return data.fixedHeightLayout === true;
  }

  private getActivatedRouteData(activatedRoute: ActivatedRoute | null): RouteData {
    return activatedRoute
      ? { ...activatedRoute.snapshot.data, ...this.getActivatedRouteData(activatedRoute?.firstChild) }
      : {};
  }
}
