import { Injectable } from '@angular/core';
import { LoadingComponent } from '../../shared/core/components/loading/loading.component';
import { TranslationService } from './translation.service';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NotificationComponent,
  NotificationConfig,
  NotificationMessageType,
} from '../../shared/core/components/notification/notification.component';
import { ActionButtonType } from '../../shared/core/components/action-bar/action-bar.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MessageBoxOverlayConfig, MessageBoxService } from '@akelius/angular-ui-kit';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private DIALOG_LIFETIME = 5000;

  constructor(
    public translationService: TranslationService,
    private matDialog: MatDialog,
    private messageBoxService: MessageBoxService
  ) {}

  showCustomNotification<T>(title: string, config: NotificationConfig): Observable<T> {
    return this._openPopup<T>(title, config);
  }

  showLoading(): MatDialogRef<LoadingComponent> {
    return this.matDialog.open(LoadingComponent);
  }

  showSuccessDialog(message: string, translationParams = {}): void {
    const shouldTranslateMessageWithParams = Object.keys(translationParams).length;
    const translatedMessage = shouldTranslateMessageWithParams
      ? this.translationService.getTranslationByIdAndParams(message, translationParams)
      : this.translationService.getTranslationById(message);

    const config: MessageBoxOverlayConfig = {
      type: NotificationMessageType.success,
      text: translatedMessage,
      duration: this.DIALOG_LIFETIME,
    };
    this.messageBoxService.open(config);
  }

  showErrorDialog(
    message: string,
    title: string | null = 'core.notifications.title.error',
    translate = true
  ): Observable<boolean> {
    const dialogEvent: Subject<boolean> = new Subject();
    const config: MessageBoxOverlayConfig = {
      type: NotificationMessageType.error,
      text: translate ? this.translationService.getTranslationById(message) : message,
      actionButtonText: this.translationService.getTranslationById('core.button.ok'),
      onActionButtonClicked: (_) => dialogEvent.next(true),
    };
    this.messageBoxService.open(config);
    return dialogEvent;
  }

  showInformationDialog(
    message: string,
    title = 'core.notifications.title.information',
    translate = true
  ): Observable<boolean> {
    const dialogEvent: Subject<boolean> = new Subject();
    const config: MessageBoxOverlayConfig = {
      type: NotificationMessageType.info,
      text: translate ? this.translationService.getTranslationById(message) : message,
      showCloseButton: true,
      onMessageBoxClosed: (_) => dialogEvent.next(true),
    };
    this.messageBoxService.open(config);
    return dialogEvent;
  }

  showConfirmDialog(message: string, translationParams = {}): Observable<boolean> {
    const shouldTranslateMessageWithParams = Object.keys(translationParams).length;
    const translatedMessage = shouldTranslateMessageWithParams
      ? this.translationService.getTranslationByIdAndParams(message, translationParams)
      : this.translationService.getTranslationById(message);
    const config: NotificationConfig = {
      type: NotificationMessageType.warning,
      message: translatedMessage,
      hideClose: true,
      buttons: [
        [
          {
            type: ActionButtonType.neutral,
            text: this.translationService.getTranslationById('core.button.cancel'),
            value: false,
          },
          {
            type: ActionButtonType.primary,
            text: this.translationService.getTranslationById('core.buttons.confirm'),
            value: true,
          },
        ],
      ],
    };
    return this._openPopup<boolean>('core.notifications.title.confirm', config);
  }

  showDeleteDialog(message: string, translationParams = {}, translate = true) {
    const shouldTranslateMessageWithParams = Object.keys(translationParams).length;
    let translatedMessage: string;
    if (translate) {
      translatedMessage = shouldTranslateMessageWithParams
        ? this.translationService.getTranslationByIdAndParams(message, translationParams)
        : this.translationService.getTranslationById(message);
    } else {
      translatedMessage = message;
    }
    const config: NotificationConfig = {
      type: NotificationMessageType.warning,
      message: translatedMessage,
      hideClose: true,
      buttons: [
        [
          {
            type: ActionButtonType.neutral,
            text: this.translationService.getTranslationById('core.button.cancel'),
            value: false,
          },
          {
            type: ActionButtonType.danger,
            text: this.translationService.getTranslationById('core.button.delete'),
            value: true,
            testId: 'delete',
          },
        ],
      ],
    };
    return this._openPopup<boolean>('core.notifications.title.delete', config);
  }

  showHelpTextDialog(message: string, title = 'core.notifications.title.information', translate = true) {
    const config: NotificationConfig = {
      type: NotificationMessageType.info,
      message: translate ? this.translationService.getTranslationById(message) : message,
      buttons: [
        [
          {
            type: ActionButtonType.primary,
            text: this.translationService.getTranslationById('core.button.ok'),
            value: true,
          },
        ],
      ],
      hideClose: true,
    };

    return this._openPopup<boolean>(title, config);
  }

  private _openPopup<T>(title: string, config: NotificationConfig): Observable<T> {
    const popup = this.matDialog.open<NotificationComponent<T>>(NotificationComponent);
    const component = popup.componentInstance;

    component.config = config;
    component.title = title;
    component.buttonClicked.pipe(untilDestroyed(component)).subscribe({
      next: (_response) => {
        popup.close();
      },
    });
    return component.buttonClicked;
  }
}
