import { Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT_TYPE_ICON_LIST } from './document-type-icon.config';
import { DocumentTypeIconConfig } from './models/document-type-icon.interface';

@Pipe({
  name: 'documentTypeIcon',
})
export class DocumentTypeIconPipe implements PipeTransform {
  transform(documentType: string): string {
    const icon: DocumentTypeIconConfig | undefined = DOCUMENT_TYPE_ICON_LIST.find(
      (iconConfig: DocumentTypeIconConfig) => iconConfig.types.find((type: string) => documentType.indexOf(type) >= 0)
    );

    return icon ? icon.icon : 'ak-document';
  }
}
