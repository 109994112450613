import { Validators } from '@angular/forms';
import { TypeOption } from '@ngx-formly/core/lib/models';
import * as fromTypes from '../types';
import { FormlyFieldType } from '../models';

export const types: TypeOption[] = [
  {
    name: FormlyFieldType.file,
    component: fromTypes.FormlyFileComponent,
    wrappers: ['form-field'],
  },
  {
    name: FormlyFieldType.propertyAssetSelector,
    component: fromTypes.FormlyPropertyAssetSelectorComponent,
  },
  {
    name: FormlyFieldType.fileUploadStepper,
    component: fromTypes.FormlyFileUploadStepperComponent,
  },
  {
    name: FormlyFieldType.pictureAsset,
    component: fromTypes.FormlyPictureAssetComponent,
  },
  {
    name: FormlyFieldType.textarea,
    component: fromTypes.FormlyFieldTextAreaComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      props: {
        rows: 4,
      },
    },
  },
  {
    name: FormlyFieldType.datepicker,
    component: fromTypes.FormlyDatePickerComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      props: {
        datepickerOptions: {},
      },
    },
  },
  {
    name: FormlyFieldType.dateRangePicker,
    component: fromTypes.FormlyDateRangePickerComponent,
  },
  {
    name: FormlyFieldType.button,
    component: fromTypes.FormlyButtonComponent,
    defaultOptions: {},
  },
  {
    name: FormlyFieldType.autoComplete,
    component: fromTypes.FormlyAutoCompleteComponent,
  },
  {
    name: FormlyFieldType.radio,
    component: fromTypes.FormlyRadioGroupComponent,
  },
  {
    name: FormlyFieldType.textFilter,
    wrappers: ['form-field'],
    component: fromTypes.FormlyFieldTextFilterComponent,
    defaultOptions: {
      defaultValue: null,
      wrappers: [], // prevent the default wrapper being created as the mat-form-field is in the component
    },
  },
  {
    name: FormlyFieldType.email,
    extends: FormlyFieldType.input,
    defaultOptions: {
      defaultValue: null,
      validators: {
        validation: [Validators.email],
      },
    },
  },
  {
    name: FormlyFieldType.repeatSection,
    component: fromTypes.FormlyRepeatSectionComponent,
  },
];
