import { Inject, Injectable } from '@angular/core';
import { DataService } from '../../../core/services/data.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PropertySearchResult } from '../../../shared/functional/ui-formly/models/property-asset-search.interafces';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class PropertySearchInputService extends DataService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) {
    super();
  }

  getPropertySearchResult(searchString): Observable<PropertySearchResult> {
    const params = this.toHttpParams({
      costcenternumberbeginswith: searchString,
      include_inactive_properties: true,
      loadAddress: false,
      loadFinancialKeyfacts: false,
      loadKeyfacts: false,
      size: 50,
      page: 0,
    });

    return this.http
      .get<PropertySearchResult>(`${this.appConfig.backend}properties`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }
}
