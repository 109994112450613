import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AkeliusIDToken } from '../models/akelius-id-token.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getTokenSilently$().pipe(
      switchMap((token: string) => {
        return this.authService
          .getIdToken$()
          .pipe(map<AkeliusIDToken, [string, AkeliusIDToken]>((idToken: AkeliusIDToken) => [token, idToken]));
      }),
      mergeMap(([token, idToken]: [string, AkeliusIDToken]) => {
        const tokenReq =
          req.url.indexOf(this.appConfig.backend) >= 0
            ? req.clone({
                withCredentials: false,
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                  'x-akelius-id-token': idToken?.__raw, // looks hacky but approved by Andreas Grimm
                },
              })
            : req;

        return next.handle(tokenReq);
      }),
      catchError((err) => throwError(err))
    );
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
