import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-05-15T08:04:23+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '85148f6fee7b22398f9b1696252085efb2edf847',
  },
};
