import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { StorageObject } from '../models/storage.interfaces';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { TranslationLoaderService, TranslationsLoaded } from './translation-loader.service';
import { TranslationNamespace } from '../models/translation.variables';
import { SentryCustomEventType, SentryService } from './sentry.service';
import { STORAGE_OBJECT, WINDOW_OBJECT } from '../core.module';
import { APP_CONFIG, AppConfig } from '../../../app.config';

export interface TranslationMap {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends DataService {
  private readonly PREVIOUS_LANGUAGE_KEY = 'int.language.previous';
  private readonly CURRENT_LANGUAGE_KEY = 'int.language.current';
  private readonly MARK_TRANSLATIONS_KEY = 'int.markTranslations';
  private readonly TEN_MINUTES = 1000 * 60 * 10;

  translationList$ = new BehaviorSubject<TranslationMap | null>(null);

  markTranslations = false;

  // We only want to log each missing translation once per user, per running application to
  // reduce the amount of requests made
  loggedTranslations: { [key: string]: boolean } = {};

  previousLanguageCode$ = new BehaviorSubject<string | null>(this.getPreviousLanguage());
  currentLang$ = new BehaviorSubject<string | null>(null);

  private timerSubscription: Subscription;

  constructor(
    private http: HttpClient,
    @Inject(STORAGE_OBJECT) private storage: StorageObject,
    @Inject(WINDOW_OBJECT) private window: Window,
    private sentryService: SentryService,
    private loader: TranslationLoaderService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    super();

    this.markTranslations = this.storage.localStore.getItem(this.MARK_TRANSLATIONS_KEY) === 'true';
  }

  loadTranslations(languageCode?: string): Observable<boolean> {
    const code = languageCode ? languageCode : this.getCurrentLanguage();
    this.translationList$.next(null);
    this.currentLang$.next(code);
    return this.loader.loadAllTranslations(code).pipe(
      tap({ next: (translationLoaded: TranslationsLoaded) => this.handleTranslations(translationLoaded) }),
      map((translationLoaded: TranslationsLoaded) => translationLoaded.fallbackLoaded)
    );
  }

  private handleTranslations(translationLoaded: TranslationsLoaded) {
    // initial translations after app start or language change
    // --> use the result even if it is fallback
    this.translationList$.next(translationLoaded.translations);
    // clear possibly old timer
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    // start new timer
    this.timerSubscription = this.initTranslationsLoadingTimer().subscribe({
      next: (translationsData: TranslationsLoaded) => {
        // only want to UPDATE translations here --> do NOT use fallback
        if (!translationsData.fallbackLoaded) {
          this.translationList$.next(translationsData.translations);
        }
      },
    });
  }

  private initTranslationsLoadingTimer(): Observable<TranslationsLoaded> {
    return timer(0, this.TEN_MINUTES).pipe(
      switchMap((_) => this.loader.loadAllTranslations(this.getCurrentLanguage()))
    );
  }

  public getTranslationById(id: string | null, namespace?: TranslationNamespace): string {
    if (!id) {
      return '';
    }

    let response: string;

    const idWithPrefix = namespace ? namespace + id : id;

    const translationList = this.translationList$.getValue();

    if (translationList && translationList[idWithPrefix]) {
      const preparedTranslation = translationList[idWithPrefix].replace(/#/g, '"');

      if (this.markTranslations) {
        const isProcaTranslation = idWithPrefix.startsWith(TranslationNamespace.procaUiConfig);
        const emoji = isProcaTranslation ? '🏘️' : '✅';
        return `${emoji} ${preparedTranslation}`;
      } else {
        return preparedTranslation;
      }
    } else {
      response = this._markTranslationFailure(idWithPrefix);
      this.logFailedLookup(idWithPrefix);
    }
    return response;
  }

  private _markTranslationFailure(translation: string) {
    const translationParts = translation.split('.');
    // if we are not marking the translations then we only send back the last part of the translation ID
    // so that users don't see the namespaces for unrecognised tags (invalid tags loaded from other
    // applications
    return this.markTranslations ? '❌ ' + translation : translationParts[translationParts.length - 1];
  }

  /**
   * Handles placeholders like '{0}' in translations. Arg params is an array ['my foo', 'bar']
   * placeholders are replaced with corresponding index
   */
  public getTranslationByIdAndParamsArray(id: string, params: string[], namespace?: TranslationNamespace): string {
    const paramsObject: { [key: number]: string } = {};
    if (params) {
      let index = 0;
      params.forEach((value) => (paramsObject[index++] = value));
    }

    return this.getTranslationByIdAndParams(id, paramsObject, namespace);
  }
  /**
   * Handles placeholders like '{foo}' in translations. Arg params is a map, example {foo: 'my foo'}
   */
  public getTranslationByIdAndParams(
    id: string,
    params: { [key: string]: string | number },
    namespace?: TranslationNamespace
  ): string {
    const translation = this.getTranslationById(id, namespace);

    return translation.replace(/{.+?}/g, function (placeholder) {
      const countMatch = placeholder.match(/count:(\w+),(\w+),(\w+)/);
      if (countMatch) {
        const [, countPlaceholder, singular, plural] = countMatch;
        const count = params[countPlaceholder] != null ? params[countPlaceholder] : 0;
        return count === 1 ? singular : plural;
      }

      const paramName = placeholder.replace(/{(\w+)}/, '$1'); // placeholder without brackets
      return params[paramName] != null ? (params[paramName] as string) : paramName;
    });
  }

  /**
   * needed for not translated and concatenated values
   * Example: "architectureanddesign,business,construction,customer"
   */
  getTranslatedListValue(value) {
    if (!value) {
      return '';
    }
    return value
      .split(',')
      .map((item) => this.getTranslationById('dynamic.node.filter.tag.' + item))
      .join(', ');
  }

  toggleMarkTranslations() {
    this.markTranslations = !this.markTranslations;
    this.storage.localStore.setItem(this.MARK_TRANSLATIONS_KEY, this.markTranslations.toString());
    this.window.location.reload();
  }

  // loads the translations in the new language.
  // observable emits a boolean which indicates whether fallback translations were used
  updateLanguage(newLanguage: string): Observable<boolean> {
    return this.currentLang$.pipe(
      take(1),
      tap({
        next: (previousLanguage) => {
          this.storage.localStore.setItem(this.CURRENT_LANGUAGE_KEY, newLanguage);
          this.storage.localStore.setItem(this.PREVIOUS_LANGUAGE_KEY, previousLanguage || '');
          this.previousLanguageCode$.next(previousLanguage);
        },
      }),
      switchMap((_) => {
        return this.loadTranslations(newLanguage);
      })
    );
  }

  private getPreviousLanguage(): string | null {
    return this.storage.localStore.getItem(this.PREVIOUS_LANGUAGE_KEY);
  }

  private getCurrentLanguage() {
    const currentLanguage = this.storage.localStore.getItem(this.CURRENT_LANGUAGE_KEY);
    return currentLanguage ? currentLanguage : 'en';
  }

  private logFailedLookup(translationId: string) {
    const message = 'Missing translation: ' + translationId;

    if (this.appConfig.useSentry) {
      if (!this.loggedTranslations[translationId]) {
        this.loggedTranslations[translationId] = true;
        this.sentryService.logCustomEvent(message, {
          type: SentryCustomEventType.translationMissing,
          payload: translationId,
          url: this.window.location.toString(),
        });
      }
    } else {
      console.error(message);
    }
  }

  translateProcaMessages(messages: string[]): string {
    return messages
      .reduce((acc: string[], message: string) => {
        // There come no translationKeys for error messages from ProCa for now. So do not try to add prefix and to translate.
        // This feature may come in future. So lets just wait and keep this line of code :)
        // const translatedMessage = this.getTranslationById(TRANSLATION_PREFIXES.procaUiConfig + message);
        const translatedMessage = message;
        acc.push(translatedMessage.length > 1 ? translatedMessage : message);
        return acc;
      }, [])
      .join('\n\n');
  }
}
