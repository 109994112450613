<div class="lightbox">
  <div class="overlay">
    <ng-container *ngIf="configSubject | async as config">
      <button data-test-lightbox-close class="overlay__close" mat-icon-button (click)="closeLightbox()">
        <mat-icon svgIcon="ak-close" class="large-icon"></mat-icon>
      </button>

      <button *ngIf="config.showPreviousArrow" (click)="prevHandler()" mat-icon-button class="overlay__prev">
        <mat-icon svgIcon="ak-chevron-left"></mat-icon>
      </button>

      <button *ngIf="config.showNextArrow" (click)="nextHandler()" mat-icon-button class="overlay__next">
        <mat-icon svgIcon="ak-chevron-right"></mat-icon>
      </button>

      <div class="overlay__crud-container">
        <button *ngIf="config.showSaveButton" (click)="saveImage()" mat-icon-button>
          <mat-icon svgIcon="ak-download"></mat-icon>
        </button>

        <button *ngIf="config.showDeleteButton" (click)="deleteImage()" mat-icon-button>
          <mat-icon svgIcon="ak-delete"></mat-icon>
        </button>

        <button *ngIf="config.showEditButton" (click)="editImage()" mat-icon-button>
          <mat-icon svgIcon="ak-edit"></mat-icon>
        </button>
      </div>

      <img
        [hidden]="(stateSubject | async) !== states.IMAGE_LOADED"
        (load)="imageLoaded()"
        (error)="imageLoadFailed()"
        data-test-lightbox-image
        class="lightbox-image"
        [src]="config.imageUrl"
        alt=""
      />
    </ng-container>

    <mat-spinner *ngIf="(stateSubject | async) === states.IMAGE_LOADING" class="overlay__loading"></mat-spinner>
  </div>
</div>
