/**
 * Module must be in CommonJS format
 */

const packageName = '@akelius/icon-library';

/**
 * Source folder within the package
 */
const sourceDir = 'icons';

/**
 * Icon names to copy
 */
const generalIcons = [
  'ak-arrow-up',
  'ak-audio',
  'ak-chat-bubble',
  'ak-plus',
  'ak-check',
  'ak-chevron-down',
  'ak-chevron-up',
  'ak-chevron-left',
  'ak-chevron-right',
  'ak-close',
  'ak-contacts',
  'ak-delete',
  'ak-document',
  'ak-download',
  'ak-edit',
  'ak-event',
  'ak-exit-app',
  'ak-export',
  'ak-external-link',
  'ak-error',
  'ak-fullscreen',
  'ak-fullscreen-exit',
  'ak-globe',
  'ak-globe-web',
  'ak-help',
  'ak-image',
  'ak-info',
  'ak-info-filled',
  'ak-initial',
  'ak-location',
  'ak-mail',
  'ak-menu',
  'ak-padlock-open',
  'ak-person',
  'ak-reception',
  'ak-remote-desk-booking',
  'ak-replay',
  'ak-search',
  'ak-share',
  'ak-sort',
  'ak-sort-asc',
  'ak-sort-desc',
  'ak-success',
  'ak-support',
  'ak-swap',
  'ak-video',
  'ak-warning',
  'ak-upload',
  'ak-unlink',
  'ak-filter-list',
  'ak-clipboard',
  'ak-three-dots-horizontal',
  'ak-target',
  'ak-unfold-more',
  'ak-unfold-less',
  'ak-table',
];

const proCaIcons = [
  'ak-antenna-sat',
  'ak-armchair',
  'ak-attic',
  'ak-balcony',
  'ak-basement',
  'ak-bathtub',
  'ak-bed',
  'ak-bike',
  'ak-billboard',
  'ak-boiler-room',
  'ak-building',
  'ak-building-part',
  'ak-car',
  'ak-closet',
  'ak-commercial',
  'ak-common-area',
  'ak-computer',
  'ak-cost-center',
  'ak-couch',
  'ak-desk-lamp',
  'ak-dining-room-set',
  'ak-document',
  'ak-drying-area',
  'ak-dumbbell',
  'ak-elevator',
  'ak-energy',
  'ak-floor-plan',
  'ak-forest-area',
  'ak-game-controller',
  'ak-garden-flower',
  'ak-gate',
  'ak-gear',
  'ak-ground',
  'ak-hallway',
  'ak-hallway',
  'ak-hunting-area',
  'ak-hanger',
  'ak-heating',
  'ak-laundry',
  'ak-loggia',
  'ak-mailbox',
  'ak-maintenance-room',
  'ak-move-from',
  'ak-move-to',
  'ak-parking-lot',
  'ak-pram',
  'ak-property',
  'ak-rentable-areas',
  'ak-rentable-buildings',
  'ak-rentable-storage',
  'ak-room',
  'ak-spanner',
  'ak-staircase',
  'ak-storage-room',
  'ak-storey',
  'ak-stove',
  'ak-technical-room',
  'ak-terrace',
  'ak-toilet',
  'ak-trash-can-floor',
  'ak-unknown-asset',
  'ak-ventilation',
  'ak-veranda',
  'ak-vestibule',
  'ak-water-drop-hot',
];

const geoIcons = [
  'ak-geo-country',
  'ak-geo-district',
  'ak-geo-metropolitan-area',
  'ak-geo-municipality',
  'ak-geo-neighborhood',
  'ak-geo-province',
];

const staffIcons = ['ak-tree'];

const iconList = [...generalIcons, ...proCaIcons, ...geoIcons, ...staffIcons];

const spriteList = ['ak-appicon-sprite', 'ak-file-sprite'];

/**
 * Destination folder relative to your project root
 */
const destDir = './src/assets/icons';

const resourceUrl = 'assets/icons';

module.exports = {
  packageName,
  sourceDir,
  iconList,
  spriteList,
  destDir,
  resourceUrl,
};
