import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { iconList, spriteList } from '../../../../icons.config';
import { forkJoin, from, Observable } from 'rxjs';
import { tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private readonly iconList: string[] = iconList;
  private readonly spriteList: string[] = spriteList;

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  get icons(): string[] {
    return this.iconList;
  }

  registerIcons(): Observable<[string[], string[]]> {
    const iconObservable = from(this.iconList).pipe(
      tap({
        next: (icon) => {
          const iconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon}.svg`);
          this.iconRegistry.addSvgIcon(icon, iconUrl);
        },
      }),
      toArray()
    );

    const spriteObservable = from(this.spriteList).pipe(
      tap({
        next: (sprite) => {
          const spriteUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${sprite}.svg`);
          this.iconRegistry.addSvgIconSet(spriteUrl);
        },
      }),
      toArray()
    );

    return forkJoin([iconObservable, spriteObservable]);
  }

  filter(value: string): string[] {
    return this.icons.filter((icon) => icon.toLowerCase().includes(value.toLocaleLowerCase()));
  }
}
