import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

// services
import { NotFoundError } from '../common';
import { NotificationService } from '../services/notification.service';
import { ContextFacadeService } from '../facades/context-facade.service';

// config
import { MODULE_TEMPLATE_NAME_MAPPING } from '../models/module-template-name-mapping.config';

// rxjs
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DynamicRouteGuard  {
  private errorHandlerService = inject(ErrorHandler);

  constructor(
    private router: Router,
    private contextFacadeService: ContextFacadeService,
    private notificationService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // check whether the path node is already loaded. This is important to make scroll position work
    const urlWithoutParams = state.url.split('?')[0];

    return this.contextFacadeService.context$.pipe(
      take(1),
      switchMap((context) => {
        return context?.path === urlWithoutParams ? of(context) : this.contextFacadeService.loadNodes(state.url, true);
      }),
      switchMap((context: any) => {
        if (context?.template?.name && MODULE_TEMPLATE_NAME_MAPPING[context.template.name]) {
          const path = MODULE_TEMPLATE_NAME_MAPPING[context.template.name].path;

          const urlTree = this.router.parseUrl(path);
          urlTree.queryParams = { ...route.queryParams, origin: state.url };
          return of(urlTree);
        }

        return of(true);
      }),
      catchError((error) => {
        console.error('error', error);
        // not found, show and error and redirect the user to root

        return this.contextFacadeService.context$.pipe(
          take(1),
          map((context) => {
            if (error instanceof NotFoundError) {
              if (context) {
                this.notificationService.showErrorDialog('core.error.pathNotFound');
                return false;
              } else {
                return this.router.parseUrl('/');
              }
            } else {
              this.errorHandlerService.handleError(error);
              // if we already have context defined, we just show a popup
              // if this is the first node load then we consider the app to be unavailable
              if (context) {
                this.notificationService.showErrorDialog('core.error.navigationFailed');
                return false;
              } else {
                this.router.navigateByUrl('/app-unavailable', { skipLocationChange: true }).then();
                return false;
              }
            }
          })
        );
      })
    );
  }
}
