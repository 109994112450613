<div class="breadcrumbs">
  <nav class="link-list">
    <ng-container *ngFor="let pathElement of path; let idx = index">
      <ng-container *ngIf="idx < path.length - 1">
        <ng-container *ngIf="{ path: path, index: idx, truncate: truncate } | callback: isTruncatePlaceholder">
          <span class="truncate-placeholder" (click)="onClickPlaceholder()">...</span>
          <i class="material-icons icon">chevron_right</i>
        </ng-container>
        <ng-container *ngIf="{ path: path, index: idx, truncate: truncate } | callback: isPathElement">
          <a [routerLink]="pathElement.path">{{ pathElement.title }}</a>
          <i class="material-icons icon">chevron_right</i>
        </ng-container>
      </ng-container>
    </ng-container>
  </nav>

  <div class="title-holder">
    <h1 class="page-title" data-test-id="page-title">
      <button *ngIf="showBackButton && path.length > 0" class="btn-none">
        <i class="material-icons back-button">chevron_left</i>
      </button>
      <span class="page-title-text">{{ path[path.length - 1] ? path[path.length - 1].title : rootPathName }}</span>
    </h1>
  </div>
</div>
