import { DocumentTypeIconConfig } from './models/document-type-icon.interface';

export const DOCUMENT_TYPE_ICON_LIST: DocumentTypeIconConfig[] = [
  /*
   * application/x-7z-compressed
   */
  {
    icon: 'ak-file-7z',
    types: ['x-7z-compressed'],
  },
  /*
   * application/x-tika-ooxml
   */
  {
    icon: 'ak-file-cab',
    types: ['x-tika-ooxml'],
  },
  /*
   * application/gzip
   */
  {
    icon: 'ak-file-gzip',
    types: ['gzip'],
  },
  /*
   * application/zip
   */
  {
    icon: 'ak-file-zip',
    types: ['zip'],
  },
  /*
   * application/json
   */
  {
    icon: 'ak-file-json',
    types: ['json'],
  },
  /*
   * application/vnd.openxmlformats-officedocument.wordprocessingml.document
   */
  {
    icon: 'ak-file-docx',
    types: ['wordprocessingml.document'],
  },
  /*
   * application/msword
   */
  {
    icon: 'ak-file-doc',
    types: ['msword'],
  },
  /*
   * application/vnd.openxmlformats-officedocument.wordprocessingml.template
   */
  {
    icon: 'ak-file-dotx',
    types: ['wordprocessingml.template'],
  },
  /*
   * application/vnd.ms-excel.sheet.macroEnabled.12
   * application/vnd.ms-excel.sheet.macroenabled.12
   */
  {
    icon: 'ak-file-xlsm',
    types: ['ms-excel.sheet'],
  },
  /*
   * application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   */
  {
    icon: 'ak-file-xlsx',
    types: ['spreadsheetml.sheet'],
  },
  /*
   * application/vnd.ms-excel
   */
  {
    icon: 'ak-file-xls',
    types: ['ms-excel'],
  },
  /*
   * application/vnd.ms-powerpoint
   */
  {
    icon: 'ak-file-ppt',
    types: ['powerpoint'],
  },
  /*
   * application/vnd.openxmlformats-officedocument.presentationml.presentation
   */
  {
    icon: 'ak-file-pptx',
    types: ['presentation'],
  },
  /*
   * application/vnd.openxmlformats-officedocument.spreadsheetml.template
   */
  {
    icon: 'ak-file-xltx',
    types: ['spreadsheetml.template'],
  },
  /*
   * application/pdf
   */
  {
    icon: 'ak-file-pdf',
    types: ['pdf'],
  },
  /*
   * image/gif
   */
  {
    icon: 'ak-file-gif',
    types: ['gif'],
  },
  /*
   * image/jpeg
   */
  {
    icon: 'ak-file-jpg',
    types: ['jpeg'],
  },
  /*
   * image/png
   */
  {
    icon: 'ak-file-png',
    types: ['png'],
  },
  /*
   * image/vnd.dwg
   */
  {
    icon: 'ak-file-dwg',
    types: ['vnd.dwg'],
  },
  /*
   * image/vnd.dxf; format=ascii
   */
  {
    icon: 'ak-file-dxf',
    types: ['vnd.dxf'],
  },
  /*
   * text/plain
   */
  {
    icon: 'ak-file-txt',
    types: ['text/plain'],
  },
  /*
   * application/rtf
   */
  {
    icon: 'ak-file-rtf',
    types: ['rtf'],
  },
  /*
   * application/vnd.ms-outlook
   */
  {
    icon: 'ak-file-pst',
    types: ['ms-outlook'],
  },
  /*
   * application/vnd.ms-xpsdocument
   */
  {
    icon: 'ak-file-xps',
    types: ['ms-xpsdocument'],
  },
  /*
   * image/tiff
   */
  {
    icon: 'ak-file-tiff',
    types: ['tiff'],
  },
  /*
   * message/rfc822
   */
  {
    icon: 'ak-file-rfc',
    types: ['rfc822'],
  },
  /*
   * text/html
   */
  {
    icon: 'ak-file-html',
    types: ['html'],
  },
  /*
   * DEFAULT ICON
   * application/octet-stream (unknown)
   */
];
