import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { APP_BASE_HREF } from '@angular/common';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../core.module';
import { StorageObject } from '../models/storage.interfaces';
import { STORAGE_KEY_TENANT_ID, TENANT_NAME_AKELIUS_RESIDENTIAL } from '../services/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  APP_BASE_HREF = inject(APP_BASE_HREF);
  URL_TENANT_ID = inject(URL_TENANT_ID) as string;
  storage = inject(STORAGE_OBJECT) as StorageObject;
  window = inject(WINDOW_OBJECT) as Window;

  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      switchMap((loggedIn) => {
        if (!loggedIn) {
          this.auth.login(state.url);
          return of(false);
        }
        return this.auth.getTenants$().pipe(
          combineLatestWith(this.auth.hasRole('User')),
          map(([tenants, hasUserRole]) => {
            // if the user does not have the User role then they are never allowed to use Intranet
            if (!hasUserRole) {
              this.redirectToUnauthorizedPage();
              return false;
            }

            // if the tenant is not in the URL, then we add the first tenant from the user's token
            if (this.APP_BASE_HREF === '/' && !!tenants.length) {
              const tenant = this.getSelectedTenant(tenants);
              this.window.location.href = `/t/${tenant}` + state.url;
              return false;
            } else if (!this.URL_TENANT_ID || !tenants.includes(this.URL_TENANT_ID)) {
              this.redirectToUnauthorizedPage();
              return false;
            }
            return true;
          }),
        );
      }),
    );
  }

  private redirectToUnauthorizedPage() {
    this.router.navigateByUrl('/app-access-unauthorised', { skipLocationChange: true });
  }

  getSelectedTenant(tenants: string[]): string {
    const tenantId = this.storage.localStore.getItem(STORAGE_KEY_TENANT_ID) as string;

    if (tenants.includes(tenantId)) {
      return tenantId;
    } else if (tenants.includes(TENANT_NAME_AKELIUS_RESIDENTIAL)) {
      return TENANT_NAME_AKELIUS_RESIDENTIAL;
    }

    return tenants[0];
  }
}
