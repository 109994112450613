import { Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TenantService } from '../services/tenant.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenantService, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tenantService.activeTenant$.pipe(
      filter((selectedTenant) => !!selectedTenant),
      take(1),
      switchMap((selectedTenant) => {
        const headerValue = selectedTenant.id;
        const newReq =
          headerValue && (this.isIntranetRequest(req) || this.isStaffRequest(req))
            ? req.clone({
                setHeaders: {
                  'x-akelius-tenant': headerValue,
                },
              })
            : req;
        return next.handle(newReq);
      }),
      catchError((err) => throwError(err))
    );
  }

  private isIntranetRequest(req: HttpRequest<any>): boolean {
    return req.url.indexOf(this.appConfig.backend) !== -1;
  }

  private isStaffRequest(req: HttpRequest<any>): boolean {
    const backendConfig = this.appConfig.staffApp.backend;

    return req.url.indexOf(backendConfig.gql) !== -1 || req.url.indexOf(backendConfig.rest) !== -1;
  }
}

export const TenantInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TenantInterceptor,
  multi: true,
};
