import { LoadingComponent } from './components/loading/loading.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { DocumentControlComponent } from './components/document-control/document-control.component';
import { HelpTextIconComponent } from './components/help-text-icon/help-text-icon.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { NoResultsFoundComponent } from './components/no-results-found/no-results-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AccessControlDirective } from './directives/access-control.directive';
import { AppIconPipe } from './pipes/app-icon/app-icon.pipe';
import { CallbackPipe } from './pipes/callback/callback.pipe';
import { DefaultValuePipe } from './pipes/default-value/default-value.pipe';
import { FormatDatePipe } from './pipes/format-date/format-date.pipe';
import { PluckPipe } from './pipes/pluck/pluck.pipe';
import { SecuredUrlPipe } from './pipes/secured-url/secured-url.pipe';
import { CurrencyFormatterPipe } from './pipes/currency-formatter/currency-formatter.pipe';
import { UploadFilePreviewComponent } from './components/upload-file-preview/upload-file-preview.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TabNavBarComponent } from './components/ak-tab-link/tab-nav-bar.component';
import { DocumentTypeIconPipe } from './pipes/document-type-icon/document-type-icon.pipe';

export const components: any[] = [
  DocumentControlComponent,
  HelpTextIconComponent,
  LightboxComponent,
  NoResultsFoundComponent,
  NotificationComponent,
  LoadingComponent,
  ActionBarComponent,
  UploadFilePreviewComponent,
  ProgressBarComponent,
  TabNavBarComponent,
];

export const pipes = [
  AppIconPipe,
  CallbackPipe,
  CurrencyFormatterPipe,
  DefaultValuePipe,
  DocumentTypeIconPipe,
  FormatDatePipe,
  PluckPipe,
  SecuredUrlPipe,
];

export const directives = [AccessControlDirective];

export { LoadingComponent } from './components/loading/loading.component';

export * from './components/loading/loading.component';
export * from './components/action-bar/action-bar.component';
export * from './components/document-control/document-control.component';
export * from './components/help-text-icon/help-text-icon.component';
export * from './components/lightbox/lightbox.component';
export * from './components/no-results-found/no-results-found.component';
export * from './components/notification/notification.component';
export * from './directives/access-control.directive';
export * from './pipes/app-icon/app-icon.pipe';
export * from './pipes/callback/callback.pipe';
export * from './pipes/default-value/default-value.pipe';
export * from './pipes/format-date/format-date.pipe';
export * from './pipes/pluck/pluck.pipe';
export * from './pipes/secured-url/secured-url.pipe';
export * from './pipes/currency-formatter/currency-formatter.pipe';
export * from './components/upload-file-preview/upload-file-preview.component';
export * from './components/progress-bar/progress-bar.component';
export * from './components/ak-tab-link/tab-nav-bar.component';
export * from './pipes/document-type-icon/document-type-icon.pipe';
